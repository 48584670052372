.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  height: 230px;
  padding: 8px;
  margin: 27px;
  background-image: linear-gradient(#ffffffd9, #ffffffd9), url('src/assets/card-background.png');
  border-radius: 10px;
}

.cardBox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 150px;
  text-align: center;
  border-radius: 10px;
}

.cardBoxTitle {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
}

.cardTextTitle {
  align-items: center;
  padding: 10px 5px 0;
  margin-bottom: 7px;
  overflow-wrap: break-word;
}

.cardBoxSubtitle {
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-content: space-around;
  margin-top: 14px;
}

.btnContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.linkButton {
  text-decoration: none;
}

.buttonCard {
  height: 30px;
  padding: 5px 10px;
  text-decoration: none;
}
